import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getBestTextColor, getButtonGradient } from "../../utils/color";

const DatoCmsCtaStyled = styled.div`
    text-align: center;
    font-size: 1em;
    margin: 3em 0;
    a {
        background-color: ${({ $color }) => $color};
        background: ${({ $color }) => getButtonGradient($color)};
        color: ${({ $color }) => getBestTextColor($color)};

        box-shadow: 0 4px 25px rgb(0 0 0 / 20%);

        text-decoration: none;
        padding: 1em 2.5em;
        border-radius: 2em/50%;
        font-weight: bold;
        overflow: hidden;
        position: relative;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: " ";
            background-color: rgba(255, 255, 255, 0.2);
            overflow: hidden;
            border-radius: 2em/50%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &:hover:after {
            opacity: 1;
        }
    }
`;

const DatoCmsCta = ({ record }) => (
    <DatoCmsCtaStyled $color={record.color.hex}>
        <Link target="_blank" to={record.link}>
            {record.title}
        </Link>
    </DatoCmsCtaStyled>
);

DatoCmsCta.propTypes = {
    record: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        color: PropTypes.shape({
            hex: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
export default DatoCmsCta;
