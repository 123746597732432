import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import { useLocationInitialization } from "../../utils/hooks";
import DefaultTemplate from "../../templates/default-template";
import theme from "../../utils/theme";
import Header from "./header";
import CenterFrame from "../common/center-frame";
import LoggedUserOnly from "../common/logged-user-only";
import BlockedContent from "./blocked-content";
import BlogContent from "./blog-content";
import Spacer from "../common/spacer";

function BlogPage({ data, location, pageContext: { locale } }) {
    const { blog } = data;
    const i18nData = data.allDatoCmsI18N.nodes[0];

    useLocationInitialization(location);

    return (
        <DefaultTemplate
            i18nData={i18nData}
            locale={locale}
            pathname={location.pathname}
            title={blog.title}
            seoMetaTags={blog.seoMetaTags}
        >
            <ThemeProvider theme={theme}>
                <Header />
                <CenterFrame>
                    <LoggedUserOnly
                        active={blog.requireLogin}
                        blockedContent={
                            <BlockedContent>{i18nData.blogBlockedContent}</BlockedContent>
                        }
                    >
                        <BlogContent blog={blog} />
                    </LoggedUserOnly>
                </CenterFrame>
                <Spacer $height="80px" />
            </ThemeProvider>
        </DefaultTemplate>
    );
}

BlogPage.propTypes = {
    data: PropTypes.shape({
        blog: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            headerImage: PropTypes.shape({ url: PropTypes.string }),
            content: PropTypes.shape({}).isRequired,
            lang: PropTypes.string.isRequired,
            requireLogin: PropTypes.bool,
            allowComments: PropTypes.bool,
            tags: PropTypes.string,
            seoMetaTags: PropTypes.shape({}),
        }),
        allDatoCmsI18N: PropTypes.shape({
            nodes: PropTypes.arrayOf(
                PropTypes.shape({
                    blogBlockedContent: PropTypes.string,
                })
            ),
        }),
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
};

export default BlogPage;
