import React from "react";
import PropTypes from "prop-types";
import { UserContext } from "./user-zone";

const LoggedUserOnly = ({ children, blockedContent = null, active = true }) => (
    <UserContext.Consumer>
        {({ user, ready }) => {
            if (!active) {
                return children;
            }
            if (ready && user) {
                return children;
            }
            return blockedContent;
        }}
    </UserContext.Consumer>
);

LoggedUserOnly.propTypes = {
    children: PropTypes.node,
    blockedContent: PropTypes.node,
    active: PropTypes.bool,
};

LoggedUserOnly.defaultProps = {
    children: null,
    blockedContent: null,
    active: true,
};

export default LoggedUserOnly;
