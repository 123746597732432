import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const HeaderImageImg = styled.img`
    margin: 2em auto;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
`;

const HeaderImage = ({ image }) => {
    if (image) {
        return <HeaderImageImg src={image.url} alt={image.alt} />;
    }
    return null;
};

HeaderImage.propTypes = {
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
    }),
};

HeaderImage.defaultProps = {
    image: null,
};

export default HeaderImage;
