import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { StructuredText } from "react-datocms";
import { Disqus } from "gatsby-plugin-disqus";

import DatoCmsImage from "./block-DatoCmsImage";
import DatoCmsCta from "./block-DatoCmsCta";
import HeaderImage from "./header-image";
import Spacer from "../common/spacer";

const getBlogUrl = (blog) => `${process.env.GATSBY_SITE_URL}/${blog.lang}/blog/${blog.slug}`;
const getBlogIdentifier = (blog) => `blog-${blog.id}`;

const Title = styled.div`
    font-size: 3em;
    font-weight: bold;
    line-height: 1.2em;
    text-shadow: 0 0 15px white;
    margin: 1em 0;
`;

const BlogContentStyle = styled.div`
    padding: 20px;
    max-width: 733px;
    margin: 0 auto;
    h1,
    h2,
    h3 {
        line-height: 1.2em;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        font-weight: bold;
    }
    h1 {
        font-size: 3em;
    }
    h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.2em;
    }
    p {
        line-height: 1.4em;
        margin-bottom: 1em;
    }
    strong,
    b {
        font-weight: bold;
    }
    ul {
        list-style: disc;
        padding-left: 2em;
    }
    min-height: calc(100vh - 300px);
`;

const Credits = styled.div`
    opacity: 0.5;
    margin: 0.8em 0;
`;
const BlogContent = ({ blog }) => (
    <BlogContentStyle>
        <Title>{blog.title}</Title>
        <Credits>{blog.credits}</Credits>
        <HeaderImage image={blog.headerImage} />
        <StructuredText
            data={blog.content}
            renderBlock={({ record }) => {
                // eslint-disable-next-line  no-underscore-dangle
                switch (record.__typename) {
                    case "DatoCmsImage":
                        return <DatoCmsImage image={record.image} />;
                    case "DatoCmsCta":
                        return <DatoCmsCta record={record} />;
                    default:
                        return null;
                }
            }}
        />
        <Spacer $height="50px" />
        {blog.allowComments && (
            <Disqus
                config={{
                    url: getBlogUrl(blog),
                    identifier: getBlogIdentifier(blog),
                    title: blog.title,
                    language: blog.lang,
                }}
            />
        )}
    </BlogContentStyle>
);

BlogContent.propTypes = {
    blog: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        headerImage: PropTypes.shape({ url: PropTypes.string }),
        content: PropTypes.shape({}).isRequired,
        lang: PropTypes.string.isRequired,
        allowComments: PropTypes.bool,
        credits: PropTypes.string,
    }).isRequired,
};

export default BlogContent;
