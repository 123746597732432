import styled from "styled-components";

const Header = styled.div`
    background: transparent;
    height: ${({ theme }) => theme.headerHeight};
    @media only screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
        height: ${({ theme }) => theme.headerHeightMobile};
    }
    position: relative;
    left: 0;
    right: 0;
`;

export default Header;
