import styled from "styled-components";

const BlockedContent = styled.div`
    padding: 20px;
    font-size: 2rem;
    text-align: center;
    min-height: calc(100vh - 300px);
`;

export default BlockedContent;
