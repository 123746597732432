import tinycolor from "tinycolor2";

/**
 * Return the text and shadow color for a given background color
 * @param hexcolor html hex 6 digit color i.e. #345678
 * @returns {string} text-color
 */
export function getBestTextColor(basecolor) {
    const hexcolor = basecolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
}

export function getButtonGradient(basecolor) {
    const startColor = tinycolor(basecolor).toString();
    const endColor = tinycolor(basecolor).spin(-20).lighten(5).toString();
    return `
    linear-gradient(
        2deg,
        ${endColor} 0%,
        ${startColor} 60%,
        ${startColor} 100%
    )
    `.trim();
}
