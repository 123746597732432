import { graphql } from "gatsby";

import BlogPage from "../components/blog/blog-page";

export default BlogPage;

export const query = graphql`
    query ($id: String, $locale: String) {
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
                ...BlogData
            }
        }

        blog: datoCmsBlogEntry(id: { eq: $id }) {
            ...BlogEntryData
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
`;
