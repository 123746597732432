import styled from "styled-components";
import React from "react";

const Image = styled(({ className, image }) => (
    <img className={className} src={image.url} alt={image.alt} />
))`
    margin: 1em auto;
    display: block;
    max-height: 50vh;
    min-height: 200px;

    max-width: 80vw;
    min-width: 200px;
`;

export default Image;
